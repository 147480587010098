@import "_base.scss";

body, html {
  height: 100%;
  width: 100%;
}

.tile-card {
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  margin: 15px;
  padding: 20px;
  color: #091E42;
  animation: fGLASt 0.6s cubic-bezier(0.15, 1, 0.33, 1) forwards;
  box-sizing: border-box;
  text-align: left;
  z-index: 100;
  box-shadow: 0 1px 1px rgba(23, 43, 77, 0.2), 0 0 0.5px 0 rgba(23, 43, 77, 0.25);
  transition: all 0.3s cubic-bezier(0.15, 1, 0.33, 1);
  animation-delay: 0.5s;
  background-size: contain;
  background: #FFFFFF none no-repeat bottom;
}

.page {
  display: flex;
  flex: 1 1 auto;
  min-height: 100%;
  width: 100%;
}

.navigator-container {
  width: 320px;
  min-width: 320px;
  position: relative;
  z-index: 999999999;
}

.navigator {
  width: inherit;
  height: 100vh;
  transition: height 0.25s ease-in-out;
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  z-index: 300;

  .menu {
    text-align: center;
    align-items: center;
    color: #DEEBFF;
    box-sizing: border-box;
    flex-direction: column;
    padding: 16px 0;
    width: 70px;
    min-width: 70px;
  }

  .nav {
    margin-top: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .nav-image-item {
    margin: 12px 0;
    cursor: pointer;
  }

  .tabs-container {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 64px;
    display: flex;
  }

  .tabs-menu {
    align-items: center;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex: none;
    background-color: transparent;
    color: #42526E;
    text-decoration: none;
    padding: 8px 0;
  }

  .tabs {
    display: flex;
    background-color: #F4F5F7;
    color: #42526E;
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }

  .tabs-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow: auto;
    position: relative;
    margin-top: 25px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tabs-list-item {
    align-items: center;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex: none;
    color: #42526E;
    padding: 8px;
    text-decoration: none;
    font-size: 14px;

    &:hover, &.active {
      background-color: rgba(9, 30, 66, 0.04);
    }
  }

  & #close-menu-button {
    display: none;
    position: absolute;
    right: 1px;
    top: 1px;
    padding: 5px;
    cursor: pointer;
  }
}

.header-navigator {
  position: fixed;
  top: 0;
  padding: 10px;
  height: auto;
  background-color: #1c45a5;
  color: #FFFFFF;
  width: 100%;
  display: none;
  z-index: 99999999;
  box-shadow: 0 1px 1px rgba(23, 43, 77, 0.2), 0 0 0.5px 0 rgba(23, 43, 77, 0.25);

  & > div#menu-button {
    position: absolute;
    left: 10px;
  }

}

.body-container {
  flex-grow: 1;
  padding: 3rem;
  overflow: auto;
}

.body {
  position: relative;
  z-index: 1;
}

.table-of-content {
  min-width: 240px;
  max-width: 260px;
  padding: 3rem 0;
  font-size: 14px;

  .toc {

    & li {
      padding: 3px 2px;

      & > ul {

        & > li {
          font-size: 13px;
          padding-left: 10px
        }
      }
    }

    & a {
      color: #4a6696df;

      &:hover, &:active {
        color: #091E42;
      }
    }
  }
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 15px;
}

@media screen and (max-width: 768px) {
  .body-container {
    padding: 5rem 2rem 2rem;
  }

  .navigator-container {
    display: none;

    & #close-menu-button {
      display: block;
    }
  }

  .header-navigator {
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 840px) {
  .table-of-content {
    display: none;
  }
}
